<template>
  <main class="kb-main" id="kb-myclass-notice">
    <lxp-main-header :show-title="true" :show-back="true" :title="notiTrsm.notiTitle">
      <template v-slot:inner>
        <div v-if="notiTrsm.notiFileCnt > 0" class="header-column">
          <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
            <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg">
              <i class="icon-download32"></i><span class="text">첨부파일</span>
            </button>
            <div class="kb-form-dropdown-options">
              <div class="dropdown-option">
                <ul class="dropdown-option-list">
                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" title="KB ACE Academy(DT개발) Pre-Step_생존을 위한 IT 필수지식+한 번에 끝내는 데이터 분석 툴.pdf"><span class="dropdown-option-text">KB ACE Academy(DT개발) Pre-Step_생존을 위한 IT 필수지식+한 번에 끝내는 데이터 분석 툴.pdf</span><span class="download-mb">1.0MB</span></a></li>
                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link is-active" title="KB 가계여신실무(2022-01).pdf"><span class="dropdown-option-text">KB 가계여신실무(2022-01).pdf</span><span class="download-mb">1.0MB</span></a></li>
                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" title="2022년 Jump-up 과정 1차.pdf"><span class="dropdown-option-text">2022년 Jump-up 과정 1차.pdf</span><span class="download-mb">1.0MB</span></a></li>
                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" title="2022년 Jump-up 과정 2차.pdf"><span class="dropdown-option-text">2022년 Jump-up 과정 2차.pdf</span><span class="download-mb">1.0MB</span></a></li>
                  <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" title="KB 자산관리를 위한 포트폴리오 설계.pdf"><span class="dropdown-option-text">KB 자산관리를 위한 포트폴리오 설계.pdf</span><span class="download-mb">1.0MB</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container">
        <div class="view-header">
          <h3 class="title">{{ notiTrsm.notiTitle }}</h3>
          <p class="subtitle"><span class="text">{{ notiTrsm.notiSbjt }}</span><span class="text"> {{ timestampToDateFormat(notiTrsm.notiTrsmCmptnDt, 'yyyy-MM-dd, hh:mm') }} 발송</span></p>
        </div>
        <div class="view-body min-component">
          <div class="view-content" v-html="notiTrsm.notiCn"></div>
        </div>
        <div class="view-bottom">
          <button class="kb-btn kb-btn-primary" @click="clickNotiDelete"><span class="text">알리미 삭제</span></button>
        </div>
      </div>
    </div>
    <!-- //main-content -->

  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_DELETE_NOTI_TRSM, ACT_GET_NOTI_TRSM} from '@/store/modules/notification/notification';
import {getItem, isSuccess, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/util';
import {useRoute, useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: 'TrainNotiView',
  components: {LxpMainHeader},
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const {showConfirm, showMessage} = useAlert();

    const showAttachments = ref(false);

    const notiTrsm = reactive({
      notiTrsmSn: route.params.trainNotiSn,
      notiTitle: '',
      notiSbjt: '',
      notiCn: '',
      notiTrsmCmptnDt: ''
    });

    const getNotiTrsm = () => {
      store.dispatch(`notification/${ACT_GET_NOTI_TRSM}`, route.params.trainNotiSn).then(res => {
        if(lengthCheck(res)) {
          const item = getItem(res);
          setParams(notiTrsm, item);
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const clickNotiDelete = () => {
      showConfirm({
        text: '알림을 삭제 하시겠습니까?',
        callback: () => {
          deleteNoti();
        }
      })
    }

    const deleteNoti = () => {
      store.dispatch(`notification/${ACT_DELETE_NOTI_TRSM}`, notiTrsm.notiTrsmSn).then(res => {
        if(isSuccess(res)) {
          showMessage('삭제 완료 되었습니다.');
          router.push({name: 'TrainNotification'});
        }
      })
    }

    getNotiTrsm();

    return {
      showAttachments,
      notiTrsm,

      timestampToDateFormat,
      clickNotiDelete
    }

  }
}
</script>